header {
    background-color: #FE931E;
    text-align: center;
  }
  
  nav ul {
    
    height: 60px;
    display: flex; /* make the nav display in a row */
    align-items: center;
    justify-content: space-between; /* set justify content to space equally */
    list-style: none;
    margin: 0;
    padding: 0;

  }
  
  nav ul li {
    display: inline-block;
  }
  
  nav ul li a {
    color: white;
    text-decoration: none;
    padding: 1rem;
    font-size: 32px;
    font-weight: bold;
  }
  