@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');


.App {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  color: white;
}

.es-widget-header-details-title{
  color: white
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.ol{

  width: 315px;

}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bkg {
  display: block;
  width: 100%;
  height: auto;
}

.tw{
  width: 800px;
}

.ctr {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.inst {


}

.heading {
  text-align: center;
  margin-top: 30px;
  color: #286988;
  font-size: 28px;
  font-weight: 800;
  margin-bottom: 50px;  
}

.about {

  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 22px;
  margin: 30px;
}


label {

  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  margin: 15px;
color: white;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

input {
  width: 20px;
}
.desc {
margin-bottom: 20px;
color: #FE931E;
}

.about1 {

  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 22px;
  margin: 30px;
}

.desc1 {
margin-bottom: 20px;
color: #286988;
display: flex;
flex-direction: row;
 align-items: center;
  margin-bottom: 60px
}

.nav-bar {
  width:100%;
  height:100px;
  border-bottom: 2px solid white;
  margin-top: -60px;
}

.carda {
  width: 36rem;
  height: 1000px;
  text-align: center;
  font-family:'Inter';
  padding: 20px; 
  border-radius: 40px;

  
}


.carda1 {
  width: 36rem;
  height: 600px;
  text-align: center;
  font-family:'Inter';
  padding: 20px; 
 
  border-radius: 40px;


  
}

.btn {
  font-size: 36px !important;
  width: 200px; 
  margin-top: 20px;
  bottom: -600px;
 position: fixed;
 margin-left: -100px;
 margin-bottom: -50px;

}


/* 
.carousel-container {
  position: relative;
  margin: auto;
  touch-action: pan-y;
  overflow-x: hidden;
height: 800px !important;
}

.carousel-track {
  display: flex;
  
  height: 100%;

  
}

.carousel-card {
  flex: 0 0 300px;
  
  height: 100%;
  display: flex;

  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  color: white;
  -webkit-tap-highlight-color: transparent;
  margin-right: 60px !important;
  width: 50%;

}

.carousel-card-inner {
  flex-direction: column;

  height: 290px;

  border-radius: 5px;
}

.carousel-title {
  margin-top: 20px;
  font-size: 1.5em;
  font-size: 44px !important
}
.carousel-price {

  font-size: 1.5em;


  position: fixed;
  width: 500px;
  font-size: 44px !important


}


.carousel-text {
  padding: 1em;
  font-size: 14px;
  white-space: pre-wrap;
  text-align: left;
  font-family: sans-serif;
  font-size: 40px !important;


}

.carousel-text1 {

  font-size: 14px;
  white-space: pre-wrap;
  text-align: left;
  font-family: sans-serif;
}

.carousel-pagination-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -700px;
  display: flex;
  justify-content: center;
  pointer-events: none;
}

.carousel-pagination1 {
  list-style: none;
  display: flex;
  justify-content: space-around;
  width: 180px;
  padding: 0;
}

.carousel-pagination {
  margin-top: -420px;
  list-style: none;
  display: flex;
  justify-content: space-around;
  width: 180px;
  padding: 0;
}

.carousel-pagination li {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(50, 50, 50, 0.5);
}

.carousel-pagination .current {
  border-radius: 0;
  background: white;
} */

.carousel-container {
  position: relative;
  height: 300px;
  /* max-width: 960px; */
  margin: 0 auto;
  overflow: hidden;
  touch-action: pan-y;
}

.carousel-track {
  display: flex;
  height: 100%;
}

.carousel-card {
  flex: 0 0 300px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  color: white;
  -webkit-tap-highlight-color: transparent;
}

.carousel-card-inner {
  flex-direction: column;
  width: 290px;
  height: 290px;
  border-radius: 5px;
}

.carousel-title {
  margin-top: 20px;
  font-size: 1.5em;
}

.carousel-text {
  padding: 1em;
  font-size: 14px;
  white-space: pre-wrap;
  text-align: left;
  font-family: sans-serif;
}

.carousel-pagination-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  display: flex;
  justify-content: center;
  pointer-events: none;
}

.carousel-pagination {
  list-style: none;
  display: flex;
  justify-content: space-around;
  width: 180px;
  padding: 0;
}

.carousel-pagination li {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(50, 50, 50, 0.5);
}

.carousel-pagination .current {
  border-radius: 0;
  background: white;
}



footer {
  background-color: #FE931E;
  height: 260px; /* added height */
  padding: 20px 0;

}

.footer-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* added height */
}

.icn {
  width: 60px;
}
.r {
  width: 50px;
}
.footer-icons a {
  display: inline-block;
  margin: 0 10px;
  color: #fff;
  font-size: 24px;
  transition: all 0.2s ease-in-out;
  line-height: 100px; /* centered vertically */
}

.footer-icons a:hover {
  transform: scale(1.2);
}

small {
  color: white;
}

.nvbr{
  font-size: 28px;
    background-color: #FE931E;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    color: white !important;
}

@media screen and (min-width: 700px) {
.nb{
  width: 100%;
  overflow: auto;
  color: white !important;
}

label {

  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  margin: 10px;
color: white;
}

.k {
  float: left;
  padding: 12px;
  color: white;
  color: white !important;
  text-decoration: none;
  font-size: 17px;
  width: 20%; /* Four equal-width links. If you have two links, use 50%, and 33.33% for three links, etc.. */
  text-align: center; /* If you want the text to be centered */
}
}
.leftt{
  text-align: left;
  margin-bottom: 10px;

}

.ra{
margin-left: 120px;
}


.ma{
  margin-right: 120px;
  }
  

.abt{
  margin-right: 100px;
   margin-left: 100px;
    color:black
}

@media screen and (max-width: 1400px) {

  .abt{
    margin-right: 10px;
     margin-left: 10px;
      color:black
  }

  .ra{
    margin-left: 0px;
    }
    
    
    .ma{
      margin-right: 0px;
      }
  .desc1{

      padding: 1em;
      font-size: 20px;
      white-space: pre-wrap;
      text-align: center;
      font-family: 'Montserrat', sans-serif;
      font-size: 20px !important;
      margin-bottom: 10px;
color: #286988;
display: flex;
flex-direction: column;
 align-items: center;
  margin-bottom: 10px
    
  }

  #rev{
    flex-direction: column-reverse;
  }
}
@media screen and (max-width: 700px) {
  .tw{
    width: 350px;
  }
  

  .icn {
    width: 40px;
  }
  .r{
    width: 34px;
  }
  .pos{
    text-align: center;
  }
  .ctr {
    width: 140px;

}

.bkg{
  height: 360px;
}
.heading {
  font-size: 26px;
}
.nst {
  width: 180px;
}

.ol{

    width: 180px;
  
}

.pos {
  font-size: 20px;
}

.desc{
  font-size: 24px;
}

.e {
  font-size: 20px;
}

#blog{
  margin-top: -10px;
  margin-bottom: 10px;
}

/* .carda {
  width: 2rem;
  height: 600px;
  text-align: center;
  font-family: 'Inter';
  padding: 20px; 
  border-radius: 40px;

  
}

.carda1 {
  width: 24rem;
  height: 600px;
  text-align: center;
  font-family: 'Inter';
  padding: 20px; 
  border-radius: 40px;

  
}

.carousel-card-inner {
  flex-direction: column;

  height: 600px;

  border-radius: 5px;
}

.carousel-title {
  margin-top: 20px;
  font-size: 20px;
  font-size: 24px !important
}
.carousel-price {

  font-size: 20px;


  position: relative;
  width: 500px;
  font-size: 24px !important;
left: -90px;

}


.carousel-text {
  padding: 1em;
  font-size: 20px;
  white-space: pre-wrap;
  text-align: left;
  font-family: sans-serif;
  font-size: 20px !important
}

.carousel-text1 {
  padding: 1em;
  font-size: 20px;
  white-space: pre-wrap;
  text-align: left;
  font-family: sans-serif;
  font-size: 20px !important;
  padding-bottom: 20px !important;
} */

.btn {
  font-size: 24px !important;
  width: 200px; 
  margin-top: 20px;
  bottom: 100px;
 position: fixed;
 margin-left: -100px;
 /* margin-bottom: -50px; */

}

/* #team {
  margin-top: -740px;
} */

.nvbr{
  font-size: 20px;
    background-color: #FE931E;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    color: white !important;


}
/* .carousel-card {
  margin-right: 140px !important;
  padding-left: 300px;
  padding-right: 10px;
} */

.k{
  color: white !important;
}
/* 
footer {

  margin-top: -660px;

} */
.leftt img{
width: 180px;
}

.heading{
  margin-bottom: 10px;
}

.about1{
  margin-top: -10px;
}
  }


  #flagButton {
    border: 0px;
    background-size: 36px 20px;
    height: 20px;
    width: 36px;
  }



  @media screen and (min-width: 900px) {
  .zaba {
  display:flex;
  flex-direction: row;
  justify-content:center;
  }
}